.app {
  min-height: 100vh;
  width: 100%; /* Your fix: avoids viewport scrollbar issue */
  overflow-x: hidden;
  background: #121212;
  position: relative;
}

.main-content {
  margin-top: 100px; /* Space for Navbar */
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}