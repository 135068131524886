.wallet-connector {
    font-family: 'Inter', sans-serif;
    background: #1a1a1a;
    border-radius: 20px;
    padding: 30px;
    width: 100%;
    max-width: 500px; /* Consistent with CoinCreator */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    color: #ffffff;
  }
  .wallet-connector h1, .wallet-btn {
    font-family: 'Inter', sans-serif;
  }
  .wallet-connector h1 {
    font-size: 1.5em;
    margin-bottom: 20px;
    text-align: center;
    font-weight: 600;
  }
  
  .error-message {
    background: #ff4444;
    color: #ffffff;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 0.9em;
  }
  
  .wallet-buttons {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .wallet-btn {
    background: #333333;
    color: #ffffff;
    border: none;
    padding: 12px 20px;
    cursor: pointer;
    border-radius: 20px;
    font-size: 1em;
    font-weight: 500;
    transition: background 0.2s ease, color 0.2s ease;
  }
  
  .wallet-btn:disabled {
    background: #555555;
    cursor: not-allowed;
  }
  
  .wallet-btn:hover:not(:disabled) {
    background: #00ccff;
    color: #1a1a1a;
  }
  
  .eth-btn {
    background: #627eea; /* Ethereum blue */
  }
  
  .eth-btn:hover:not(:disabled) {
    background: #829bff;
    color: #1a1a1a;
  }
  
  .solana-btn {
    background: #00c4b4; /* Solana teal */
  }
  
  .solana-btn:hover:not(:disabled) {
    background: #00e6d2;
    color: #1a1a1a;
  }