.navbar-brand, .navbar-link {
    font-family: 'Inter', sans-serif;
  }
  
.navbar {
    font-family: 'Inter', sans-serif;
    position: fixed;
    top: 20px; /* Vertical space from top */
    left: 50%;
    transform: translateX(-50%);
    width: 80vw; /* 80% of viewport width */
    max-width: 800px; /* Reasonable max-width */
    z-index: 1100; /* Above Sidebar */
  }
  
  .navbar-content {
    background: #1a1a1a; /* Modern dark color */
    border-radius: 50px; /* Pill shape */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3); /* Subtle shadow for depth */
  }
  
  .navbar-brand {
    font-size: 1.2em;
    font-weight: 600;
  }
  
  .navbar-logo {
    color: #ffffff;
    text-decoration: none;
    transition: color 0.2s ease;
  }
  
  .navbar-logo:hover {
    color: #00ccff; /* Light blue hover effect */
  }
  
  .navbar-links {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
  }
  
  .navbar-links li {
    margin-left: 15px;
  }
  
  .navbar-link {
    background: none;
    border: none;
    color: #ffffff;
    font-size: 1em;
    font-weight: 500;
    cursor: pointer;
    padding: 8px 16px;
    border-radius: 20px; /* Smaller pill shape for buttons */
    transition: background 0.2s ease, color 0.2s ease;
  }
  
  .navbar-link:hover {
    background: #333333; /* Subtle hover background */
    color: #00ccff;
  }