.coin-creator h1, .form-group label, .deploy-btn {
    font-family: 'Inter', sans-serif;
  }
.coin-creator {
    font-family: 'Inter', sans-serif;
    background: #1a1a1a;
    border-radius: 20px;
    padding: 30px;
    width: 100%;
    max-width: 600px; /* Consistent width */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    color: #ffffff;
  }
  
  .coin-creator h1 {
    font-size: 1.5em;
    margin-bottom: 20px;
    text-align: center;
    font-weight: 600;
  }
  
  .error-message {
    background: #ff4444;
    color: #ffffff;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 0.9em;
  }
  
  .coin-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  .form-group label {
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 0.95em;
  }
  
  .form-group input[type="text"],
  .form-group input[type="number"] {
    background: #333333;
    color: #ffffff;
    border: none;
    padding: 10px;
    border-radius: 10px;
    font-size: 1em;
    transition: background 0.2s ease;
  }
  
  .form-group input[type="text"]:focus,
  .form-group input[type="number"]:focus {
    background: #444444;
    outline: none;
  }
  
  .form-group.checkbox {
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
  
  .form-group.checkbox input[type="checkbox"] {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  
  .form-group.checkbox label {
    margin: 0;
  }
  
  .wallet-buttons {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 20px;
  }
  
  .connect-wallet-btn {
    background: #333333;
    color: #ffffff;
    border: none;
    padding: 12px 20px;
    cursor: pointer;
    border-radius: 20px;
    font-size: 1em;
    font-weight: 500;
    transition: background 0.2s ease, color 0.2s ease;
  }
  
  .connect-wallet-btn:disabled {
    background: #555555;
    cursor: not-allowed;
  }
  
  .connect-wallet-btn:hover:not(:disabled) {
    background: #00ccff;
    color: #1a1a1a;
  }
  
  .eth-btn {
    background: #627eea;
  }
  
  .eth-btn:hover:not(:disabled) {
    background: #829bff;
    color: #1a1a1a;
  }
  
  .solana-btn {
    background: #00c4b4;
  }
  
  .solana-btn:hover:not(:disabled) {
    background: #00e6d2;
    color: #1a1a1a;
  }
  
  .deploy-btn {
    background: #333333;
    color: #ffffff;
    border: none;
    padding: 12px 20px;
    cursor: pointer;
    border-radius: 20px;
    font-size: 1em;
    font-weight: 500;
    margin-top: 20px;
    transition: background 0.2s ease, color 0.2s ease;
  }
  
  .deploy-btn:disabled {
    background: #555555;
    cursor: not-allowed;
  }
  
  .deploy-btn:hover:not(:disabled) {
    background: #00ccff;
    color: #1a1a1a;
  }
  
  .success-message {
    background: #00ccff;
    color: #1a1a1a;
    padding: 15px;
    border-radius: 10px;
    margin-top: 20px;
    text-align: center;
    font-size: 0.95em;
  }
  
  .success-message p {
    margin: 5px 0;
  }