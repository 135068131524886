.sidebar-container {
    position: fixed;
    top: 100px;
    right: 60px;
    width: 250px;
    max-width: calc(100% - 120px);
    height: calc(100vh - 120px);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    gap: 15px;
    font-family: 'Inter', sans-serif;
  }
  
  .sidebar-toggle {
    background: #1a1a1a;
    color: #ffffff;
    border: none;
    padding: 12px 20px;
    cursor: pointer;
    font-weight: 500;
    font-size: 1rem;
    border-radius: 20px;
    transition: background 0.2s ease;
    width: auto;
    align-self: flex-end;
    font-family: 'Inter', sans-serif;
  }
  
  .sidebar-toggle:hover {
    background: #333333;
  }
  
  .sidebar {
    background: #1a1a1a;
    width: 100%;
    height: 100%;
    padding: 20px;
    color: #ffffff;
    border-radius: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    font-family: 'Inter', sans-serif;
  }
  
  .wallet-details p {
    margin: 12px 0;
    word-break: break-all;
    font-size: 0.9em;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .status-connected {
    color: #00ff00;
  }
  
  .status-disconnected {
    color: #ff0000;
  }
  
  .sidebar-actions {
    margin-top: 20px;
  }
  
  .sidebar-btn {
    background: #333333;
    color: #ffffff;
    border: none;
    padding: 10px;
    margin: 8px 0;
    cursor: pointer;
    width: 100%;
    border-radius: 20px;
    font-weight: 500;
    font-size: 0.95em;
    transition: background 0.2s ease, color 0.2s ease;
    font-family: 'Inter', sans-serif;
  }
  
  .sidebar-btn:hover {
    background: #00ccff;
    color: #1a1a1a;
  }
  
  .disconnect-btn {
    background: #ff4444;
  }
  
  .disconnect-btn:hover {
    background: #ff6666;
    color: #ffffff;
  }
  
  .refresh-btn {
    background: #4444ff;
  }
  
  .refresh-btn:hover {
    background: #6666ff;
    color: #ffffff;
  }
  
  .copy-btn {
    background: #555555;
    color: #ffffff;
    border: none;
    padding: 4px 8px;
    cursor: pointer;
    border-radius: 10px;
    font-size: 0.8em;
    transition: background 0.2s ease;
  }
  
  .copy-btn:hover {
    background: #777777;
  }
  
  .sidebar-links {
    margin-top: 20px;
  }
  
  .sidebar-links h3 {
    margin-bottom: 12px;
    font-size: 1.1em;
  }
  
  .sidebar-links ul {
    list-style: none;
    padding: 0;
  }
  
  .sidebar-links li {
    margin: 8px 0;
  }
  
  .sidebar-links a {
    color: #00ccff;
    text-decoration: none;
    transition: color 0.2s ease;
    font-size: 0.9em;
  }
  
  .sidebar-links a:hover {
    color: #ffffff;
  }
  
  .message-box {
    margin: 10px 0;
    padding: 10px;
    border-radius: 10px;
    font-size: 0.9em;
    text-align: center;
  }
  
  .message-box.success {
    background: #00cc00;
    color: #ffffff;
  }
  
  .message-box.error {
    background: #ff4444;
    color: #ffffff;
  }
  
  .message-box.info {
    background: #00ccff;
    color: #1a1a1a;
  }
  
  @media (max-width: 600px) {
    .sidebar-container {
      position: static;
      width: 100%;
      max-width: 100%;
      margin: 20px auto;
      height: auto;
    }
  }